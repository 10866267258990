import { Container, Row, Col, Hidden } from "react-grid-system";
import { RouteComponentProps } from "@reach/router";
import React, { FunctionComponent } from "react";
import { graphql, navigate } from "gatsby";
import StickyBox from "react-sticky-box";
import { useIntl } from "react-intl";

import SideMenu, { SelectionOptions } from "../components/common/SideMenu";
import Preview from "../components/common/Preview";
import Layout from "../components/common/Layout";
import Contact from "../components/Contact";

interface ContactUsPageProps extends RouteComponentProps {
  data: {
    prismicImage: {
      data: {
        name: string;
        image: { alt: string; url: string };
      };
    };
    prismicContactsPage: {
      data: {
        title?: string;
        description?: string;
        keywords: string;
        canonical_url?: {
          url: string;
        };
        image?: {
          alt: string;
          url: string;
        };
      };
    };
  };
}

const ContactUsPage: FunctionComponent<ContactUsPageProps> = ({ data }) => {
  const { locale, formatMessage } = useIntl();

  const handleSideMenuClick = (input: SelectionOptions): void => {
    switch (input) {
      case SelectionOptions.SOLUTIONS:
        navigate(
            `/${locale}/#solutions`,
            {
              state: {skipSolutionsAnimation: true}
            }
        );
        break;
      case SelectionOptions.SERVICES:
        navigate(`/${locale}/#services`,
            {
              state: {skipSolutionsAnimation: true}
            });
        break;
      case SelectionOptions.SUCCESSES:
        navigate(`/${locale}/#case-studies`,
            {
              state: {skipSolutionsAnimation: true}
            });
        break;
      case SelectionOptions.BLOG:
        navigate(`/${locale}/#blog`,
            {
              state: {skipSolutionsAnimation: true}
            });
        break;
    }
  };

  return (
    <Layout
      // whiteTopMenu
      // topMenuOpacity={0}
      underline="contact-us"
      backgroundImageURL={data.prismicImage.data.image.url}
      metaTitle={
        data.prismicContactsPage.data.title ||
        formatMessage({ id: "pages.contact-us.meta-title" })
      }
      metaDescription={
        data.prismicContactsPage.data.description ||
        formatMessage({
          id: "pages.contact-us.meta-description"
        })
      }
      metaKeywords={
        data.prismicContactsPage.data.keywords ||
        formatMessage({
          id: "pages.contact-us.meta-keywords"
        })
      }
      metaImage={data.prismicContactsPage.data.image}
      metaURL={data.prismicContactsPage.data.canonical_url?.url}
    >
      <Container>
        <Row>
          <Hidden xs sm>
            <Col xs={3}>
              <StickyBox style={{ zIndex: 45 }}>
                <SideMenu
                  white
                  scrollhandler={handleSideMenuClick}
                  selectedOption={SelectionOptions.CONTACTS}
                />
              </StickyBox>
            </Col>
          </Hidden>
          <Col xs={12} md={9}>
            <Contact white />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query($lang: String!) {
    prismicImage(data: { name: { eq: "Contact Page Background" } }) {
      data {
        name
        image {
          alt
          url
        }
      }
    }
    prismicContactsPage(lang: { eq: $lang }) {
      data {
        title
        description
        keywords
        canonical_url {
          url
        }
        image {
          alt
          url
        }
      }
    }
  }
`;

export default Preview(ContactUsPage);
